<template>
    <div>
        <title-bar :title="$t('navigations.statistics')"/>
        <b-row>
            <b-col lg="4">
                <b-card class="statistics">
                    <doughnut v-if="statisticRows" :entries-prop="statisticRows" :colors="colors"/>
                </b-card>
            </b-col>
            <b-col lg="8">
                <b-card class="statistics">
                    <b-button variant="primary" class="float-end" @click="showFilterModal = true">
                        Advanced filter
                    </b-button>
                    <div class="clearfix"></div>
                    <standard-chart v-if="statisticRows" :entries="statisticRows" :colors="colors"/>
                </b-card>
            </b-col>
        </b-row>
        <advanced-filter
            :fields-array="filterFields"
            :action="showFilterModal"
            @clearAction="showFilterModal = false"
            @paramsChange="setAdvancedParams"
        />
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['perPage']"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:type="data">
                    {{
                        $helper.getEnumTranslation(
                            "time_track_type",
                            data.value.type,
                            this.$i18n.locale
                        )
                    }}
                </template>
                <template v-slot:fromDate="data">
                    {{ $helper.formatDate(data.value.fromDate) }}
                    {{ $helper.formatTime(data.value.fromDate) }}
                </template>
                <template v-slot:toDate="data">
                    {{ $helper.formatDate(data.value.toDate) }}
                    {{ $helper.formatTime(data.value.toDate) }}
                </template>
                <template v-slot:project="data">
                    {{ data.value.project.name }}
                </template>
                <template v-slot:remark="data">
                    {{ data.value.remark }}
                </template>
                <template v-slot:workTime="data">
                    {{ workTime(data.value.fromDate, data.value.toDate) }}
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('time-tracking', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteActivity(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import Doughnut from "./components/Doughnut.vue";
import StandardChart from "./components/StandardChart.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "TimeTrackStats",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar,
        Doughnut,
        StandardChart,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            statisticRows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            today: new Date(),
            colors: {
                DAILY_BUSINESS: "#D98880",
                DAILY_WORK: "#DC7633",
                MANAGEMENT_WORK: "#BB8FCE",
                OTHER: "#7FB3D5",
            },
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: this.$t("forms.type"),
                    field: "type",
                    sortable: true,
                },
                {
                    label: this.$t("forms.fromDate"),
                    field: "fromDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.toDate"),
                    field: "toDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.project"),
                    field: "project",
                    sortable: true,
                },
                {
                    label: this.$t("forms.remark"),
                    field: "remark",
                    sortable: true,
                },
                {
                    label: this.$t("forms.workTime"),
                    field: "workTime",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
            filterFields: [
                {
                    label: 'forms.project',
                    name: "project",
                    value: null,
                    type: "entity",
                    multiple: true,
                    entityName: "$Projects"
                },
                {
                    label: 'forms.employee',
                    name: "employee",
                    value: null,
                    multiple: true,
                    type: "employee",
                },
                {
                    label: 'forms.type',
                    name: "type",
                    value: null,
                    type: "enum",
                    multiple: true,
                    enumName: "time_track_type"
                },
                {
                    label: 'forms.dateRange',
                    name: "dateRange",
                    value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)],
                    type: "rangeStatistics"
                },
            ],
            advancedParams: [
                {"name": "fromDate[after]", "value": new Date(new Date().getFullYear(), new Date().getMonth(), 1)},
                {"name": "toDate[before]", "value": new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$TimeTracks.getCollection({params}, "time_list").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
            delete params.itemsPerPage
            delete params.page
            params.pagination = false
            this.$TimeTracks.getCollection({params}, "time_list").then((response) => {
                this.statisticRows = response.data["hydra:member"];
            });
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        workTime(start, end) {
            let diff = new Date(end) - new Date(start);
            if (diff <= 0) {
                return "0";
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);

            return hours + " hours " + minutes + " minutes ";
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === "employee") {
                this.sortable.order = order + ".familyName";
            }
            if (order === "owner") {
                this.sortable.order = order + ".name";
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        deleteActivity(url) {
            this.deleteByUrl(
                this.$Projects,
                url,
                this.$t("messages.timeTrackerDeleted"),
                null,
                this.load,
                this.error
            );
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
