<template>
    <div class="daily-graph">
        <div class="line-graph">
            <Bar
                ref="bar"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :fill="false"
                :styles="style"
            />
        </div>
    </div>
</template>

<script>
import {Bar} from "vue-chartjs";
import '@vuepic/vue-datepicker/dist/main.css'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    components: {
        Bar,
    },
    props: {
        colors: {Object, required: true},
        entries: {Array, required: true},
    },
    mounted() {
        this.setValues();
        this.load();
    },
    watch: {
        entries() {
            this.setValues();
            this.load();
        },
    },
    data() {
        return {
            fromDate: null,
            toDate: null,
            style: {
                height: `400px`,
                position: "relative",
            },
            tableValues: {},
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: this.colors["DAILY_BUSINESS"],
                        borderColor: this.colors["DAILY_BUSINESS"],
                        label: "Daily business",
                    },
                    {
                        data: [],
                        backgroundColor: this.colors["DAILY_WORK"],
                        borderColor: this.colors["DAILY_WORK"],
                        label: "Daily work",
                    },
                    {
                        data: [],
                        backgroundColor: this.colors["MANAGEMENT_WORK"],
                        borderColor: this.colors["MANAGEMENT_WORK"],
                        label: "Management work",
                    },
                    {
                        data: [],
                        backgroundColor: this.colors["OTHER"],
                        borderColor: this.colors["OTHER"],
                        label: "Other",
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                categoryPercentage: 1,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";

                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    let dayHours = 0
                                    for (let i = 0; i < 4; i++) {
                                        dayHours += context.parsed._stacks.y[i]
                                    }
                                    let percentValue = ((context.parsed.y / dayHours) * 100).toFixed(0)
                                    label += context.parsed.y + ' hours, ' + percentValue + '%'
                                }
                                return label;
                            },
                        },
                    },
                },
            },
        };
    },
    methods: {
        setValues() {
            this.tableValues = {};
            this.entries.forEach((element) => {
                const date = new Date(element.fromDate).toDateString();
                if (!this.tableValues[date]) {
                    this.tableValues[date] = {};
                }
                if (!this.tableValues[date][element.type]) {
                    this.tableValues[date][element.type] = 0;
                }
                this.tableValues[date][element.type] += parseFloat(
                    this.workTime(element.fromDate, element.toDate)
                );
            });
        },
        load() {
            this.chartData.labels = [];
            const data0 = [];
            const data1 = [];
            const data2 = [];
            const data3 = [];
            for (const [key, value] of Object.entries(this.tableValues)) {
                this.chartData.labels.push(
                    new Intl.DateTimeFormat("en", {
                        day: "2-digit",
                        month: "2-digit",
                    }).format(new Date(key))
                );

                if (!value["DAILY_BUSINESS"]) value["DAILY_BUSINESS"] = 0;
                data0.push(value["DAILY_BUSINESS"]);

                if (!value["DAILY_WORK"]) value["DAILY_WORK"] = 0;
                data1.push(value["DAILY_WORK"]);

                if (!value["MANAGEMENT_WORK"]) value["MANAGEMENT_WORK"] = 0;
                data2.push(value["MANAGEMENT_WORK"]);

                if (!value["OTHER"]) value["OTHER"] = 0;
                data3.push(value["OTHER"]);
            }
            this.chartData.datasets[0].data = data0;
            this.chartData.datasets[1].data = data1;
            this.chartData.datasets[2].data = data2;
            this.chartData.datasets[3].data = data3;
        },
        workTime(start, end) {
            let diff = new Date(end) - new Date(start);
            if (diff <= 0) {
                return "0";
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);
            if (minutes > 0) {
                hours = hours + minutes / 60;
            }

            return hours.toFixed(2);
        },
    },
};
</script>

