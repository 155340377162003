<template>
    <div>
        <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="pie"
        />
    </div>
</template>

<script>
import {Doughnut} from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
    components: {
        Doughnut,
    },
    props: {
        colors: {Object, required: true},
        entriesProp: {Array, required: true}
    },
    created() {
        this.load()
    },
    watch: {
        entriesProp() {
            this.load()
        }
    },
    data() {
        return {
            entries: {
                'DAILY_BUSINESS': 0,
                'DAILY_WORK': 0,
                'MANAGEMENT_WORK': 0,
                'OTHER': 0
            },
            chartData: {
                labels: [
                    this.$helper.getEnumTranslation('time_track_type', 'DAILY_BUSINESS', this.$i18n.locale),
                    this.$helper.getEnumTranslation('time_track_type', 'DAILY_WORK', this.$i18n.locale),
                    this.$helper.getEnumTranslation('time_track_type', 'MANAGEMENT_WORK', this.$i18n.locale),
                    this.$helper.getEnumTranslation('time_track_type', 'OTHER', this.$i18n.locale)
                ],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed !== null) {
                                    let dayHours = 0
                                    for (let i = 0; i < 4; i++) {
                                        dayHours += context.dataset.data[i]
                                    }
                                    let percentValue = ((context.parsed / dayHours) * 100).toFixed(0)
                                    label += context.parsed.toFixed(2) + ' hours, ' + percentValue + '%'
                                }
                                return label;
                            },
                        },
                    },
                },
            },
        };
    },
    methods: {
        load() {
            this.entries = {
                'DAILY_BUSINESS': 0,
                'DAILY_WORK': 0,
                'MANAGEMENT_WORK': 0,
                'OTHER': 0
            }
            this.entriesProp.forEach(element => {
                this.entries[element.type] += parseFloat(this.workTime(element.fromDate, element.toDate))
            });
            const data = []
            data.push(this.entries['DAILY_BUSINESS']);
            data.push(this.entries['DAILY_WORK']);
            data.push(this.entries['MANAGEMENT_WORK']);
            data.push(this.entries['OTHER']);
            this.chartData.datasets[0]['data'] = data

            this.chartData.datasets[0].backgroundColor.push(this.colors['DAILY_BUSINESS']);
            this.chartData.datasets[0].backgroundColor.push(this.colors['DAILY_WORK']);
            this.chartData.datasets[0].backgroundColor.push(this.colors['MANAGEMENT_WORK']);
            this.chartData.datasets[0].backgroundColor.push(this.colors['OTHER']);
        },
        workTime(start, end) {
            let diff = new Date(end) - new Date(start);
            if (diff <= 0) {
                return '0'
            }

            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor((diff / 1000 / 60 / 60 - hours) * 60);
            if (minutes > 0) {
                hours = hours + (minutes / 60)
            }

            return hours.toFixed(2)
        }
    }
};
</script>

<style>
</style>
